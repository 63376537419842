import * as React from "react"
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import SEO from "../components/SEO";
import MyPortableText from "../components/MyPortableText";
import localize from "../components/localize";

export const query = graphql`
    {
    allSanityDatenschutz{
    edges {
      node {
      title{
            _type
            en
            de
        }
        _rawBody
          seo
          {
              seo_image {asset{gatsbyImageData}}
              seo_title {
                  _type
                  en
                  de
              }
              seo_description {
                  _type
                  en
                  de
              }
          }
      }
      }
        
    }
  allSanitySettings {
    edges {
      node {
          telefon
          sociallinks {
              value
              type
              title
          }
          email
          address_street
          address_plz
          address_name
          address_nr
          address_ort
      }
    }
  }
    site {
      siteMetadata {
        lang{
        _type
        en
        de
        }
        work{
        _type
        en
        de
        }
        aboutme{
        _type
        en
        de
        }
        contact{
        _type
        en
        de
        }
        }
        }
        allSanityImpressum{
    edges {
      node {
      title{
            _type
            en
            de
        }
      }
      }
    }
        allSanityStartseite{
            edges {
                node {
                    seo{
                        seo_title{
                            _type
                            en
                            de
                        }
                        seo_image {asset{gatsbyImageData}}
                        seo_description{
                            _type
                            en
                            de
                        }
                    }
                    servicesheadline{
                        _type
                        en
                        de
                    }
                    aboutheadline{
                        _type
                        en
                        de
                    }
                    photoheadline{
                        _type
                        en
                        de
                    }
                    videoheadline{
                        _type
                        en
                        de
                    }
                    factsheadline{
                        _type
                        en
                        de
                    }
                }
            }
        }
    }`;

const Privacy = ({data, location}) => {

    const settings = data.allSanitySettings.edges[0].node;
    const page = data.allSanityDatenschutz.edges[0].node;
    const metadata = data.site.siteMetadata;
    const startseite = data.allSanityStartseite.edges[0].node;

    return (
        <Layout settings={settings} location={location} metadata={metadata} servicesheadline={startseite.servicesheadline} videoheadline={startseite.videoheadline} photoheadline={startseite.photoheadline} aboutheadline={startseite.aboutheadline} lang={metadata.lang} animated={false} privacy={page.title} imprint={data.allSanityImpressum.edges[0].node.title}>
            <SEO title={page.seo?.seo_title} description={page.seo?.seo_description} image={page.seo?.seo_image} location={location}></SEO>
            <div className="basepage terms bg-carbon color-light">
                <h3>{page.title}</h3>
                <MyPortableText value={page._rawBody}></MyPortableText>
            </div>
        </Layout>
    )
}

export default localize(Privacy)
